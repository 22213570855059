import styled from 'styled-components'
import {COLORS} from '../utils/colors'

interface SubmitButtonProps {
    onclick: () => void
    label: string
    disabled?: boolean
}

const Button = styled.button`
    background-color: #012441;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    height: 40px;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
    }
    &:disabled {
        opacity: 0.25;
    }
`
export default function SubmitButton({
    disabled = false,
    label,
    onclick,
}: SubmitButtonProps) {
    return (
        <Button disabled={disabled} type="button" onClick={onclick}>
            {label}
        </Button>
    )
}

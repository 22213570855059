import axios, {AxiosInstance} from 'axios'
import {threadType} from '../types/thread'
import {messageType} from '../types/message'
import MyAxiosInstance from './axiosInstance'
import MyAxiosInstanceDocument from './axiosInstanceDocument'
import {AssistantType} from '../types/assistant'
import {IUserInfo} from '../types/user'
import {limitsType} from '../types/limits'
import {subscription} from '../types/subscription'
import {project} from '../types/project'
import {Question} from '../types/question'
import {Organization} from '../types/orgUsers'
import {OrgUsersMetrics} from '../types/orgUsersMetrics'

export class AssistantApi {
    post(
        arg0: string,
        projectData: {
            created_at: string
            name: string
            description: string
            updated_at: string
            start_date: string
            end_date: string
            is_active: boolean
            project_type_id: string
            status: string
        }
    ) {
        throw new Error('Method not implemented.')
    }
    private api: AxiosInstance
    private documentApi: AxiosInstance

    client = axios.create({
        baseURL: process.env.REACT_APP_DOCUMENT_URL,
    })

    constructor() {
        this.api = MyAxiosInstance
        this.documentApi = MyAxiosInstanceDocument
    }

    async getAssistants(): Promise<AssistantType[]> {
        try {
            const res: {data: AssistantType[]} = await this.api.get(
                '/assistants'
            )
            return res.data
        } catch (error) {
            console.log(error)
            const pattern: AssistantType[] = []
            return pattern
        }
    }

    async getThreads(limit = 15, skip = 0): Promise<threadType[]> {
        try {
            const res: {data: threadType[]} = await this.api.get(
                `/threads?limit=${limit}&skip=${skip}`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async unarchiveThread(threadId: string): Promise<void> {
        try {
            await this.api.put(`/threads/${threadId}/restore`)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getThread(thread_id: string): Promise<threadType> {
        try {
            const res: {data: threadType} = await this.api.get(
                `/threads/${thread_id}`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getThreadsArchived(): Promise<threadType[]> {
        try {
            const res: {data: threadType[]} = await this.api.get(
                '/threads/archived'
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getMessages(thread_id: string): Promise<messageType[]> {
        try {
            const res: {data: messageType[]} = await this.api.get(
                `/threads/${thread_id}/messages`
            )
            return res.data.reverse()
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async sendMessage(
        thread_id: string,
        content: string,
        assistant_id: string,
        author_id: string
    ): Promise<messageType> {
        try {
            const res: {data: messageType[]} = await this.api.post(
                '/messages',
                {
                    content,
                    thread_id: thread_id === 'new' ? null : thread_id,
                    assistant_id,
                    author_id,
                }
            )
            return res.data[1]
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async regenerateAnswer(thread_id: string): Promise<messageType> {
        try {
            const res: {data: messageType} = await this.api.put(
                `/threads/${thread_id}/regenerate`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getUser(): Promise<IUserInfo> {
        try {
            const res: {data: IUserInfo} = await this.api.get(`/users`)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async updateUser(user: IUserInfo): Promise<IUserInfo> {
        try {
            const res: {data: IUserInfo} = await this.api.put(
                `/users/data`,
                user
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async archiveThread(threadGuid: string): Promise<void> {
        try {
            await this.api.get(`/threads/${threadGuid}/archive
            `)
            return
        } catch (error) {
            console.log(error)
        }
    }

    async deleteThread(threadGuid: string): Promise<void> {
        try {
            await this.api.delete(`/threads/${threadGuid}/
            `)
            return
        } catch (error) {
            console.log(error)
        }
    }

    async getConstraints(): Promise<{max_characters: number}> {
        try {
            const res = await this.api.get('/users/constraints')
            return res.data
        } catch (error) {
            console.error('Erro ao obter constraints:', error)
            throw 'Erro ao obter constraints da API.'
        }
    }

    async getCurrentSubscription(): Promise<subscription> {
        try {
            const res = await this.api.get('/subscriptions/current')
            return res.data
        } catch (error) {
            console.error('Erro ao obter subscription:', error)
            throw 'Erro ao obter subscription da API.'
        }
    }

    async getLimits(): Promise<limitsType> {
        try {
            const res: {data: limitsType} = await this.api.get(`/users/limits`)
            return res.data
        } catch (error) {
            console.log(error)
            const pattern: limitsType = {
                created_at: '',
                usages: {
                    created_at: '',
                    messages: {
                        max: 0,
                        current: 0,
                    },
                    projects: {
                        max: 0,
                        current: 0,
                    },
                },
            }
            return pattern
        }
    }

    async getResume(project_id: string): Promise<any> {
        try {
            const res = await this.documentApi.get(
                `/projects/${project_id}/answers`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async postAnswer(answer: any): Promise<Question> {
        try {
            const res = await this.documentApi.post('/answers', answer)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getNextQuestion(project_id: string): Promise<Question> {
        try {
            const res = await this.documentApi.get(
                `/projects/${project_id}/next-question`
            )

            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async finishProject(project_id: string): Promise<void> {
        try {
            await this.documentApi.post(`/projects/${project_id}/finish`)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getProjects(): Promise<project[]> {
        try {
            const res = await this.documentApi.get('/projects')
            return res.data
        } catch (error) {
            console.log(error)
            throw new Error('Erro ao obter documentos da API.')
        }
    }

    async postProject(projectData: {
        created_at: string
        name: string
        description: string
        updated_at: string
        start_date: string
        end_date: string
        is_active: boolean
        project_type_id: string
        status: string
    }): Promise<Question> {
        try {
            const response = await this.documentApi.post(
                '/projects',
                projectData
            )
            return response.data
        } catch (error) {
            console.error('Erro criando projeto:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(
                    `Erro ao criar projeto: ${
                        error.response.data.message || error.response.statusText
                    }`
                )
            } else {
                throw new Error('Erro ao criar projeto: Erro desconhecido')
            }
        }
    }

    async reportAnswer(content: string, answer_guid: string): Promise<void> {
        try {
            const res: {data: messageType} = await this.api.post(
                `/answers/report`,
                {content, answer_guid}
            )
            console.log('ok', res)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async confirmPurchase(CHECKOUT_SESSION_ID: string) {
        try {
            await this.api.get(`/subscriptions/${CHECKOUT_SESSION_ID}/handle`)
            return
        } catch (error) {
            console.log('confirm purchase: ', error)
            throw 403
        }
    }

    async getOrganizationUsers(): Promise<Organization[]> {
        try {
            const res = await this.api.get('/organizations')
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getOrganizationUsersMetrics(): Promise<OrgUsersMetrics> {
        try {
            const res = await this.api.get('/organizations/users')
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async inviteUserOrganization(email: string): Promise<void> {
        try {
            await this.api.post(`/organizations/invite/user/${email}`)
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async removeUserOrganization(user_guid: string): Promise<void> {
        try {
            await this.api.delete(`/organizations/users/${user_guid}`)
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async setUserOrganization(organization_guid: string): Promise<void> {
        try {
            await this.api.put('/users/organization', {organization_guid})
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

import styled from 'styled-components'
import {COLORS} from '../../../utils/colors'
import {useState} from 'react'
import {CookiesService} from '../../../services/Cookie.service'
import Background from '../../../assets/background.svg'
import {Typography} from '@mui/material'

const texts = {
    plano1: {
        mensal: {
            quantidade: '50 Consultas/mês',
            valor: 'R$ 295,00/mês',
        },
        anual: {
            quantidade: '50 Consultas/mês',
            valor: `R$ 3.300,00/ano
            (equivalente a R$275/mês)`,
        },
    },
    plano2: {
        mensal: {
            quantidade: '150 Consultas/mês',
            valor: 'R$ 875,00/mês',
        },
        anual: {
            quantidade: '150 Consultas/mês',
            valor: `R$ 9.810,00/ano
            (equivalente a R$817,50/mês)`,
        },
    },
}
const CardsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
    @media (max-width: 970px) {
        flex-direction: column;
    }
`

const Page = styled.div`
    height: 100vh;
    margin-top: 0;
    padding: 64px 48px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;
    flex: 1;
    font-family: 'Roboto', sans-serif;
`

const Header = styled.div`
    width: 100%;
    margin-bottom: 32px;
`

export const Card = styled.div<{last?: boolean; height?: string}>`
    border-radius: 10px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    width: 250px;
    max-width: 95vw;
    gap: 20px;
    background-color: ${COLORS.branco};
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 1px 1px white;
    & h2 {
        font-size: 20px;
        font-weight: 500;

        text-align: left;
    }

    & h3 {
        font-weight: 700;
    }

    & button {
        background-color: #39cab7;
        color: ${COLORS.branco};
        font-size: 15px;
        font-weight: 700;
        width: 200px;
        padding: 8px 0;
        border-radius: 4px;
        border: none;
        box-shadow: 1px 1px 3px 1px #d4d3d3;
        cursor: pointer;
        transition: all 0.5s;
    }

    & button:hover {
        background-color: ${COLORS.branco};
        color: #39cab7;
    }
`
const Slider = styled.div`
    background-color: ${COLORS.branco};
    width: 200px;
    display: flex;
    justify-content: space-evenly;
    border-radius: 50px;
    padding: 3px;
    cursor: pointer;
`
export const Item = styled.div<{selected: boolean}>`
    text-align: center;
    padding: 3px 8px;
    font-weight: 700;
    border-radius: 50px;
    background-color: ${({selected}) => (selected ? '#39CAB7' : COLORS.branco)};
    color: ${({selected}) => (selected ? COLORS.branco : '#7E7E7E')};
    width: 100%;
`
export const Middle = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 24px;
`
export const Text = styled.p`
    color: #707070
    display: block;
    font-size: 13px;
    wheigth: 400;
`

export const ValueWrapper = styled.p`
    display: flex;
    flex-direction: column;
`
export const Consults = styled.p`
    font-size: 15px;
    white-space: pre-line;
    color: #707070;
`

export const Values = styled.p`
    font-size: 20px;
    wheight: 600;
    color: #3acdb4;
`

type slidersType = 'mensal' | 'anual'

interface CardsProps {
    incluirGratuito?: boolean
}

export default function Cards({incluirGratuito = true}: CardsProps) {
    const [sliderMode, setSliderMode] = useState<slidersType>('mensal')

    function handleClick(route: string, flag: string | null) {
        if (flag) {
            new CookiesService().saveFlag(flag)
        }
        window.location.href = route
    }

    return (
        <Page>
            <Header>
                <Typography variant="h4">Planos e pagamentos</Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: '#707070',
                        marginTop: '8px',
                    }}
                >
                    Controle seus planos e veja detalhes de pagamentos
                </Typography>
            </Header>
            <Slider>
                <Item
                    onClick={() => setSliderMode('mensal')}
                    selected={sliderMode === 'mensal'}
                >
                    Mensal
                </Item>
                <Item
                    onClick={() => setSliderMode('anual')}
                    selected={sliderMode === 'anual'}
                >
                    Anual
                </Item>
            </Slider>
            <CardsWrapper>
                {incluirGratuito && (
                    <Card>
                        <h2>{`Plano
Gratuito`}</h2>
                        <Middle>
                            <ValueWrapper>
                                <Values>Gratis</Values>
                                <Consults>3 Consultas/mês</Consults>
                            </ValueWrapper>
                            <Text>
                                Para iniciantes no universo das Licitações e
                                Contratos Administrativos.
                            </Text>
                        </Middle>
                        <button onClick={() => handleClick('/login', null)}>
                            Testar
                        </button>
                    </Card>
                )}
                <Card>
                    <h2>{`Plano 
            Profissional I`}</h2>
                    <Middle>
                        <ValueWrapper>
                            <Values>{texts.plano1[sliderMode].valor}</Values>
                            <Consults>
                                {texts.plano1[sliderMode].quantidade}
                            </Consults>
                        </ValueWrapper>
                        <Text>
                            Para quem trabalha <strong>cotidianamente</strong>{' '}
                            com Licitações e Contratos Administrativos.
                        </Text>
                    </Middle>
                    <button
                        onClick={() =>
                            handleClick(
                                `/login?plano1/${sliderMode}`,
                                `plano1/${sliderMode}`
                            )
                        }
                    >
                        Assinar
                    </button>
                </Card>
                <Card>
                    <h2>{`Plano 
            Profissional II`}</h2>
                    <Middle>
                        <ValueWrapper>
                            <Values>{texts.plano2[sliderMode].valor}</Values>
                            <Consults>
                                {texts.plano2[sliderMode].quantidade}
                            </Consults>
                        </ValueWrapper>
                        <Text>
                            Para quem trabalha intensamente e com alto volume de
                            Licitações e Contratos Administrativos
                        </Text>
                    </Middle>
                    <button
                        onClick={() =>
                            handleClick(
                                `/login?plano2${sliderMode}`,
                                `plano2/${sliderMode}`
                            )
                        }
                    >
                        Assinar
                    </button>
                </Card>
                <Card>
                    <h2>{`Plano 
                    Institucional`}</h2>
                    <Middle>
                        <Text>
                            Para equipes de{' '}
                            <strong>órgãos públicos ou empresas</strong>{' '}
                            trabalham com licitações públicas e contratos
                            administrativos.
                        </Text>
                    </Middle>
                    <button
                        onClick={() =>
                            (window.location.href = 'http://eepurl.com/iSI0PU')
                        }
                    >
                        Contato
                    </button>
                </Card>
            </CardsWrapper>
        </Page>
    )
}

import {Dispatch, SetStateAction, useState, useEffect} from 'react'
import returnIcon from '../../../../assets/Vector.svg'
import styled from 'styled-components'
import {pageType} from '../../../../types/loginPages'
import FormField from '../../../../globalComponents/formField'
import SubmitButton from '../../../../globalComponents/SubmitButton'
import {COLORS} from '../../../../utils/colors'
import privFile from '../../../../assets/files/[v1] Política de Privacidade - Fase de Testes.pdf'
import testFile from '../../../../assets/files/[v1] Política de Testes - Fase de Testes.pdf'
import termFile from '../../../../assets/files/[v1] Termos de Uso - Schief - Fase de Testes.pdf'
import PDFModal from '../../../../globalComponents/PDFModal'

const SubTitle = styled.h2`
    font-size: 18px;
    margin: 10px 0;
    color: ${COLORS.cinzaEscuro};
`

const CheckboxField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    font-size: 15px;

    & input {
        font-size: 30px;
        width: 30px;
    }

    & strong {
        text-decoration: underline;
        cursor: pointer;
    }
`

const ReturnDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 10px 0;
    gap: 8px;
    & span {
        font-size: 13px;
        color: #838383;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    & h3 {
        font-size: 15px;
        color: ${COLORS.cinzaEscuro};
    }
`

const GoToLogin = styled.a`
    margin: 10px 0;
    font-size: 13px;
    color: black;
    text-decoration: none;
`

const ErrorMessage = styled.p`
    color: red;
    font-size: 12px;
    margin: 5px 0;
`

interface CreatePasswordProps {
    handleSignUp: () => void
    password: string
    setPassword: Dispatch<SetStateAction<string>>
    setPage: Dispatch<SetStateAction<pageType>>
}

export default function CreatePassword({
    handleSignUp,
    password,
    setPassword,
    setPage,
}: CreatePasswordProps) {
    const [confirmPassword, setConfirmPassword] = useState('')
    const [checked, setChecked] = useState(false)
    const [modal, setModal] = useState(null)
    const [errors, setErrors] = useState({
        length: false,
        number: false,
        specialChar: false,
        uppercase: false,
        lowercase: false,
    })

    useEffect(() => {
        validatePassword(password)
    }, [password])

    const validatePassword = (password: string) => {
        const newErrors = {
            length: password.length < 8 || password.length > 16,
            number: !/\d/.test(password),
            specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
            uppercase: !/[A-Z]/.test(password),
            lowercase: !/[a-z]/.test(password),
        }
        setErrors(newErrors)
    }

    const isPasswordValid = () => {
        return !Object.values(errors).some((error) => error)
    }

    return (
        <Body>
            <FormField
                autofocus
                submit={handleSignUp}
                inputType="password"
                state={password}
                setState={setPassword}
                label="Senha"
            />

            <FormField
                submit={handleSignUp}
                inputType="password"
                state={confirmPassword}
                setState={setConfirmPassword}
                label="Confirmar senha"
            />

            {errors.length && (
                <ErrorMessage>
                    A senha deve ter entre 8 e 16 caracteres.
                </ErrorMessage>
            )}
            {errors.number && (
                <ErrorMessage>
                    A senha deve incluir pelo menos um número.
                </ErrorMessage>
            )}
            {errors.specialChar && (
                <ErrorMessage>
                    A senha deve incluir pelo menos um caractere especial.
                </ErrorMessage>
            )}
            {errors.uppercase && (
                <ErrorMessage>
                    A senha deve incluir pelo menos uma letra maiúscula.
                </ErrorMessage>
            )}
            {errors.lowercase && (
                <ErrorMessage>
                    A senha deve incluir pelo menos uma letra minúscula.
                </ErrorMessage>
            )}

            <CheckboxField>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked((prev) => !prev)}
                />
                <p style={{fontSize: '12px'}}>
                    Ao me cadastrar, declaro que li e concordo com os
                    <strong onClick={() => setModal(termFile)}>
                        {' '}
                        Termos de Uso{' '}
                    </strong>
                    ,
                    <strong onClick={() => setModal(privFile)}>
                        {' '}
                        Política de Privacidade{' '}
                    </strong>
                    e
                    <strong onClick={() => setModal(testFile)}>
                        {' '}
                        Política de Testes{' '}
                    </strong>
                    .
                </p>
            </CheckboxField>
            <SubmitButton
                disabled={
                    password !== confirmPassword ||
                    !password ||
                    !confirmPassword ||
                    !checked ||
                    !isPasswordValid()
                }
                label="Confirmar"
                onclick={handleSignUp}
            />
            <ReturnDiv onClick={() => setPage('Cadastre-se')}>
                <img src={returnIcon} alt="Retornar para Inscreva-se" />
                <span>
                    Voltar para <GoToLogin>Dados</GoToLogin>
                </span>
            </ReturnDiv>
            {!!modal && <PDFModal item={modal} setModal={setModal} />}
        </Body>
    )
}

import styled from 'styled-components'
import {useEffect, useState} from 'react'
import Button from '../../../../globalComponents/ButtonPattern'
import Icon from '../../../../assets/email.svg'
import Check from '../../../../assets/check.svg'
import Warning from '../../../../assets/warning.svg'
import {AuthApi} from '../../../../services/AuthApi.service'
import useError from '../../../../globalComponents/useError'
import {useLoading} from '../../../../globalComponents/useLoading'
import FormField from '../../../../globalComponents/formField'
import logoTransparente from '../../../../assets/logoTransparente.png'
import {COLORS} from '../../../../utils/colors'
import Footer from '../../../../globalComponents/footer'
import SubmitButton from '../../../../globalComponents/SubmitButton'
import returnIcon from '../../../../assets/Vector.svg'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    h1 {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    p {
        white-space: pre-line;
        text-align: center;
    }
`
const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    position: relative;
`
const Error = styled.label`
    color: ${COLORS.vermelho};
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
`
const Logo = styled.img`
    width: 180px;
    margin-bottom: 30px;
    cursor: pointer;
`
const Form = styled.div`
    width: 300px;
    display: flex;
    /* gap: 30px; */
    flex-direction: column;
    align-items: center;
`

const HeaderLogin = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90deg, #3ffe81, #3394ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
`
const Subtitle = styled.h2`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #838383;
`

const ErrorMessage = styled.p`
    color: red;
    font-size: 12px;
    margin: 5px 0;
`

const ReturnDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 10px 0;
    gap: 8px;
    & span {
        font-size: 13px;
        color: #838383;
    }
`

const GoToLogin = styled.a`
    margin: 10px 0;
    font-size: 13px;
    color: black;
    text-decoration: none;
`

export default function ResetPassword() {
    const {ErrorComponent, showError} = useError()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const {Spinner, isLoading, setIsLoading} = useLoading()
    const [buttonProps, setButtonProps] = useState({
        text: 'Enviar redefinição',
        icon: Icon,
    })
    const search = window.location.search
    const token = search.slice(7, search.length)
    const [errors, setErrors] = useState({
        length: false,
        number: false,
        specialChar: false,
        uppercase: false,
        lowercase: false,
    })

    useEffect(() => {
        validatePassword(password)
    }, [password])

    const validatePassword = (password: string) => {
        const newErrors = {
            length: password.length < 8 || password.length > 16,
            number: !/\d/.test(password),
            specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
            uppercase: !/[A-Z]/.test(password),
            lowercase: !/[a-z]/.test(password),
        }
        setErrors(newErrors)
    }

    const isPasswordValid = () => {
        return !Object.values(errors).some((error) => error)
    }

    async function handleSendTokenWithPasswordReset() {
        try {
            if (!password || password !== confirmPassword) {
                showError('Preencha corretamente os campos.', true)
            }
            await new AuthApi().sendTokenToResetPassword(token, password)
            window.location.href = '/login'
        } catch (error: any) {
            showError(error, true)
        }
    }
    async function handleSendPasswordLink() {
        if (!email) {
            return
        }
        setIsLoading(true)
        try {
            await new AuthApi().sendEmailForResetPassword(email)
            setButtonProps({
                text: `E-mail enviado`,
                icon: Check,
            })
            showError('E-mail enviado! Confira sua caixa de entrada.')
        } catch (error: any) {
            showError(error, true)
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <Page>
            <Container>
                {!token ? (
                    <>
                        <Form>
                            <FormField
                                submit={handleSendPasswordLink}
                                inputType="text"
                                state={email}
                                setState={setEmail}
                                label="Email"
                            />
                            <SubmitButton
                                disabled={isLoading}
                                onclick={handleSendPasswordLink}
                                label="Continuar"
                            />
                        </Form>
                    </>
                ) : (
                    <>
                        <HeaderLogin>
                            <Title>Crie sua senha</Title>
                            <Subtitle>Com no mínimo 8 caracteres.</Subtitle>
                        </HeaderLogin>
                        <Form>
                            <FormField
                                submit={handleSendTokenWithPasswordReset}
                                inputType="password"
                                state={password}
                                setState={setPassword}
                                label="Password"
                            />
                            <FormField
                                submit={handleSendTokenWithPasswordReset}
                                inputType="password"
                                state={confirmPassword}
                                setState={setConfirmPassword}
                                label="Digite novamente"
                            />

                            {errors.length && (
                                <ErrorMessage>
                                    A senha deve ter entre 8 e 16 caracteres.
                                </ErrorMessage>
                            )}
                            {errors.number && (
                                <ErrorMessage>
                                    A senha deve incluir pelo menos um número.
                                </ErrorMessage>
                            )}
                            {errors.specialChar && (
                                <ErrorMessage>
                                    A senha deve incluir pelo menos um caractere
                                    especial.
                                </ErrorMessage>
                            )}
                            {errors.uppercase && (
                                <ErrorMessage>
                                    A senha deve incluir pelo menos uma letra
                                    maiúscula.
                                </ErrorMessage>
                            )}
                            {errors.lowercase && (
                                <ErrorMessage>
                                    A senha deve incluir pelo menos uma letra
                                    minúscula.
                                </ErrorMessage>
                            )}
                            <SubmitButton
                                disabled={isLoading || !isPasswordValid()}
                                onclick={handleSendTokenWithPasswordReset}
                                label="Definir nova senha"
                            />
                            <ReturnDiv
                                onClick={() =>
                                    (window.location.href = '/login')
                                }
                            >
                                <img
                                    src={returnIcon}
                                    alt="Retornar para Inscreva-se"
                                />
                                <span>
                                    Voltar para <GoToLogin>Login</GoToLogin>
                                </span>
                            </ReturnDiv>
                        </Form>
                    </>
                )}
                {isLoading && <Spinner />}
                {confirmPassword?.length > 0 &&
                    password !== confirmPassword && (
                        <Error>Os campos precisam ser iguais</Error>
                    )}
                {ErrorComponent}
            </Container>
        </Page>
    )
}

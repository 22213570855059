import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

const CarouselWrapper = styled.div`
    width: 90vh; /* Ajusta a largura proporcionalmente à tela */
    height: 90vh; /* Ajusta a altura proporcionalmente à tela */
    overflow: hidden;
    position: relative;
    border-radius: 8px;
`

const CarouselTrack = styled.div<{translateX: number}>`
    display: flex;
    transform: ${({translateX}) => `translateX(${translateX}%)`};
    transition: transform 0.5s ease-in-out;
`

const CarouselImage = styled.img`
    width: 100%;
    height: 100%;
    max-height: 100%;
    flex-shrink: 0;
    object-fit: contain; /* Mantém a proporção e evita cortes */
    border-radius: 8px;
`

const NavigationButton = styled.button<{direction: 'prev' | 'next'}>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    ${({direction}) => (direction === 'prev' ? 'left: 10px;' : 'right: 10px;')}
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
`

const DotContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -5%;
    z-index: 2;
`

const Dot = styled.button<{active: boolean}>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({active}) => (active ? '#3394EF' : '#CCC')};
    border: none;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 2;
    &:hover {
        background-color: #3394ef;
    }
`

interface CarouselProps {
    images: string[]
}

const Carousel: React.FC<CarouselProps> = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        )
    }

    const goToSlide = (index: number) => {
        setCurrentIndex(index)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext()
        }, 8000) // Move a cada 5 segundos

        return () => clearInterval(interval) // Limpa o intervalo ao desmontar
    }, [currentIndex])

    return (
        <CarouselWrapper>
            <CarouselTrack translateX={-currentIndex * 100}>
                {images.map((src, index) => (
                    <CarouselImage
                        key={index}
                        src={src}
                        alt={`Slide ${index + 1}`}
                    />
                ))}
            </CarouselTrack>

            <NavigationButton direction="prev" onClick={handlePrev}>
                &#8249;
            </NavigationButton>
            <NavigationButton direction="next" onClick={handleNext}>
                &#8250;
            </NavigationButton>

            <DotContainer>
                {images.map((_, index) => (
                    <Dot
                        key={index}
                        active={index === currentIndex}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </DotContainer>
        </CarouselWrapper>
    )
}

export default Carousel

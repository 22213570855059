import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import LoginForm from './components/Form/LoginForm'
import logoLOgin from '../../assets/logoLogin.svg'
import banner1 from '../../assets/banner1.png'
import banner2 from '../../assets/banner2.png'
import banner3 from '../../assets/banner3.png'
import carrosel2 from '../../assets/imagem2.png'
import Footer from '../../globalComponents/footer'
import Background from '../../assets/background.svg'
import Carousel from '../../globalComponents/Carousel'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;
    min-height: 100vh;
    gap: 30px;
    position: relative;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 100%;
    flex: 1;
    gap: 30px;
`

const CarouselContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 38%;
    margin-left: 20px;
    margin-right: 20px;
`

const FormContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
`

const Title = styled.h1`
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90deg, #3ffe81, #3394ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
`

const Subtitle = styled.h2`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #838383;
    margin-bottom: 20px;
`

const Logo = styled.img``

const Login: React.FC = () => {
    const flag = window.location.search.replace('?', '')
    const images: string[] = [banner1, banner2, banner3]
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setwindowHeight] = useState(window.innerHeight)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
            setwindowHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const isSmallScreenw = windowWidth < 1000
    const isSmallScreenh = windowHeight < 1

    return (
        <Page>
            <ContentWrapper>
                {!isSmallScreenw && !isSmallScreenh && (
                    <CarouselContainer>
                        <Carousel images={images} />
                    </CarouselContainer>
                )}
                <FormContainer>
                    <LoginForm flag={flag} />
                </FormContainer>
            </ContentWrapper>
        </Page>
    )
}

export default Login

import styled, {keyframes} from 'styled-components'
import {COLORS} from '../../../../utils/colors'
import {useContext, useEffect, useState} from 'react'
import {HistoryContext} from '../../../../contexts/history'
import Message from './message/message'
import Input from './input/input'
import {breakpoints} from '../../../../utils/configs'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import Background from '../../../../assets/background.svg'
import {AuthContext} from '../../../../contexts/auth'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {useRef} from 'react'

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;

    @media screen and (${breakpoints.sm}) {
        width: 100vw;
    }
`

const Body = styled.div<{noMessage?: boolean}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${({noMessage}) => (noMessage ? 'justify-content: center;' : '')}
    width: 795px;
    max-width: 100%;
    overflow-y: auto;
    margin-top: 50px;

    @media (max-height: 760px) {
        margin-top: -12%;
        justify-content: flex-start;
    }
`

const Body2 = styled.div<{noMessage?: boolean}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${({noMessage}) => (noMessage ? 'justify-content: center;' : '')}
    width: 795px;
    max-width: 100%;
    overflow-y: auto;
    margin-top: 50px;
`

const BodyMessage = styled.div<{isUser?: boolean}>`
    display: flex;
    justify-content: ${({isUser}) => (isUser ? 'flex-end' : 'flex-start')};
    width: 100%;
    padding: 5px 0;
`

const SelectedSchiefMessage = styled.span`
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    text-align: center;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
`
const NoMessageText = styled.span`
    align-self: center;
    font-weight: 400;
`

const ModalContent = styled.div`
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 80%;
    max-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    outline: none;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        /* Para tablets e maiores */
        width: 60%;
    }

    @media (min-width: 1024px) {
        /* Para telas de desktop */
        width: 40%;
    }
`

const NewMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 20%;

    @media screen and (${breakpoints.md}) {
        margin-top: 15%;
    }

    @media screen and (${breakpoints.sm}) {
        margin-top: 10%;
    }
`

const TitleNewMessage = styled.h1`
    font-size: 62px;
    font-weight: 600;
    align-self: flex-start;
    background: linear-gradient(271.31deg, #3ffe81 -25.96%, #3394ef 114.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (${breakpoints.md}) {
        font-size: 48px;
    }

    @media screen and (${breakpoints.sm}) {
        font-size: 36px;
    }

    @media (max-height: 760px) {
        font-size: 36px;
    }
`

const SubtitleNewMessage = styled.h2`
    font-size: 16px;
    font-weight: 400;
    color: #838383;
    max-width: 500px;
    align-self: flex-start;
    margin-top: 20px;

    @media screen and (${breakpoints.md}) {
        font-size: 14px;
        max-width: 400px;
    }

    @media screen and (${breakpoints.sm}) {
        font-size: 12px;
        max-width: 300px;
    }
`

const SugestionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    gap: 16px;
`

const SugestionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 232px;
    min-height: 140px;
    border-radius: 8px;
    border: 0.8px solid #e0e0e0;
    padding: 10px;
    cursor: pointer;

    @media (max-height: 760px) {
        width: 150px;
        min-height: 128px;
        font-size: 12px;
    }
`

const StyledIconButton = styled(IconButton)``

const HeadModal = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
`

const BodyModal = styled.div`
    aling-content: center;
    display: flex;
    padding: 20px;
    overflow-y: auto;
`
export default function Chat() {
    const {
        messagesArray,
        selected,
        assistant,
        setUnarchieveModal,
        unarchieveModal,
        archivedThread,
        unarchiveThread,
    } = useContext(HistoryContext)
    const handleClose = () => {
        setUnarchieveModal(false)
    }
    const {userInfo} = useContext(AuthContext)
    const [suggestion, setSuggestion] = useState('')
    const body2Ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        console.log('.')
    }, [archivedThread])

    useEffect(() => {
        if (body2Ref.current) {
            body2Ref.current.scrollTop = body2Ref.current.scrollHeight
        }
    }, [messagesArray])

    const _desarquivarModal = () => {
        return (
            <Modal
                onClose={handleClose}
                open={unarchieveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disablePortal
                disableEnforceFocus
            >
                <ModalContent>
                    <HeadModal>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Chats arquivados
                        </Typography>
                        <StyledIconButton onClick={handleClose}>
                            <CloseIcon />
                        </StyledIconButton>
                    </HeadModal>
                    <Divider orientation="horizontal" flexItem />
                    <BodyModal>
                        <TableContainer component={Box}>
                            <Table
                                sx={{minWidth: 200}}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {archivedThread?.map((item) => (
                                        <TableRow
                                            key={item.guid}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {border: 0},
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <ChatOutlinedIcon
                                                    sx={{marginRight: '10px'}}
                                                />
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        unarchiveThread(
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <UnarchiveOutlinedIcon />
                                                </IconButton>
                                                {
                                                    // <IconButton>
                                                    //     <DeleteOutlinedIcon
                                                    //         onClick={() =>
                                                    //             deleteThread(
                                                    //                 item.guid
                                                    //             )
                                                    //         }
                                                    //     />
                                                    // </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BodyModal>
                </ModalContent>
            </Modal>
        )
    }
    console.log('selected', messagesArray)

    return (
        <>
            <Section>
                {!!selected?.name ? (
                    messagesArray.length == 1 ? (
                        <Body noMessage={false}>
                            <NewMessageContainer>
                                <TitleNewMessage>
                                    Olá, {userInfo.first_name} <br /> Como posso
                                    te ajudar hoje?
                                </TitleNewMessage>
                                <SubtitleNewMessage>
                                    IA Generativa & Banco de Dados sob curadoria
                                    de Gustavo Schiefler e Joel de Menezes
                                    Niebuhr
                                </SubtitleNewMessage>
                                <SugestionsContainer>
                                    <SugestionContent
                                        onClick={() =>
                                            setSuggestion(
                                                'Sou da equipe de planejamento de contratações públicas e preciso de apoio técnico-jurídico para um caso concreto. Por favor, elabore uma minuta de texto para o tópico xxxxxxxxxxxxxx do documento xxxxxxxxxxxxxxx. Considere que xxxxxxxxxxxxxx.'
                                            )
                                        }
                                    >
                                        Sou da equipe de planejamento de
                                        contratações públicas e preciso de apoio
                                        técnico-jurídico.
                                        <ArticleOutlinedIcon
                                            style={{marginTop: 'auto'}}
                                        />
                                    </SugestionContent>
                                    <SugestionContent
                                        onClick={() =>
                                            setSuggestion(
                                                'Sou pregoeiro em uma licitação que se subordina à Lei 14.133/2021 e recebi uma impugnação ao edital. Minute uma resposta à impugnação, com introdução, desenvolvimento e conclusão, apresentando o argumento da impugnação e uma análise jurídica sobre este argumento. Ao final, conclua pela procedência ou pela improcedência da impugnação. O conteúdo da impugnação é este: xxxxxxxxxxxx. Considere, para a sua análise, que xxxxxxxxxxxxxxx.'
                                            )
                                        }
                                    >
                                        Sou pregoeiro e recebi uma impugnação ao
                                        edital.
                                        <ArticleOutlinedIcon
                                            style={{marginTop: 'auto'}}
                                        />
                                    </SugestionContent>
                                    <SugestionContent
                                        onClick={() =>
                                            setSuggestion(
                                                'Sou fiscal/gestor de um contrato administrativo e preciso decidir sobre um pleito apresentado pelo particular contratado. Minute uma resposta ao pleito, com introdução, desenvolvimento e conclusão, com uma análise jurídica sobre o pleito. Ao final, conclua pela procedência ou pela improcedência. O contratado solicita que xxxxxxx, sob o argumento de que xxxxxxxx. Considere, para a sua análise, que xxxxxxxxxxx.'
                                            )
                                        }
                                    >
                                        Sou fiscal/gestor de contrato e preciso
                                        decidir sobre um pleito.
                                        <ArticleOutlinedIcon
                                            style={{marginTop: 'auto'}}
                                        />
                                    </SugestionContent>
                                    <SugestionContent
                                        onClick={() =>
                                            setSuggestion(
                                                'Preciso fazer uma análise jurídica sobre uma questão. Elabore um tópico de parecer jurídico, com introdução, desenvolvimento e conclusão, com uma análise jurídica sobre a seguinte situação: xxxxxxxxxxxx'
                                            )
                                        }
                                    >
                                        Sou advogado/procurador e preciso fazer
                                        uma análise jurídica.
                                        <ArticleOutlinedIcon
                                            style={{marginTop: 'auto'}}
                                        />
                                    </SugestionContent>
                                </SugestionsContainer>
                            </NewMessageContainer>
                        </Body>
                    ) : (
                        <Body2 ref={body2Ref} noMessage={false}>
                            {messagesArray?.map((item, index) => (
                                <BodyMessage
                                    key={`body-${item.guid}`}
                                    isUser={!!item.author_id}
                                >
                                    <Message
                                        key={item.guid}
                                        message={item}
                                        arrayLength={messagesArray.length}
                                        index={index}
                                    />
                                </BodyMessage>
                            ))}
                            <div id="anchor"></div>
                        </Body2>
                    )
                ) : (
                    <Body2 noMessage>
                        <NoMessageText>Selecione uma conversa.</NoMessageText>
                    </Body2>
                )}
                <Input suggestion={suggestion} />
            </Section>
            {_desarquivarModal()}
        </>
    )
}

import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom'
import Home from './pages/home'
import UserConfigs from './pages/userConfigs'
import Login from './pages/login'
import AuthProvider from './contexts/auth'
import {CookiesService} from './services/Cookie.service'
import Plans from './pages/plans'
import LandingPage from './pages/lp'
import ValidateEmailPage from './pages/validateEmail'
import ValidateSubscriptionPage from './pages/validateSubscription'
import ResetPassword from './pages/login/components/Form/resetPassword'
import Projetos from './pages/GeradorDocumentos/projetos'
import GeneratorWaitingPage from './pages/GeradorDocumentos/generator-waiting-page'
import GeneratorPage from './pages/GeradorDocumentos/generator-doc-page'
import DocumentGenerator from './pages/GeradorDocumentos/document-generator'
import ProjetosPage from './pages/GeradorDocumentos/projetos'

function validateToken() {
    const cookiesService = new CookiesService()
    const authData = cookiesService.getAuthData()
    const isAuthenticated = authData?.access_token
    return isAuthenticated
}

const PrivateRoute = (path: string, element: JSX.Element) => {
    const isAuthenticated = validateToken()
    return isAuthenticated ? {path, element} : {path, element: <Login />}
}

const PublicRoute = (path: string, element: JSX.Element) => {
    const isAuthenticated = validateToken() && path.includes('login')
    return isAuthenticated
        ? {path, element: <Navigate to={'/'} />}
        : {path, element}
}

const router = createBrowserRouter([
    PublicRoute('/login', <Login />),
    PrivateRoute('/', <Home />),
    PrivateRoute('/gerador', <DocumentGenerator />),
    PrivateRoute('/espera', <GeneratorWaitingPage />),
    PrivateRoute('/projetos', <ProjetosPage />),
    PrivateRoute('/', <Login />),
    PrivateRoute('/configuracoes', <UserConfigs />),
    PublicRoute('/planos', <Plans />),
    PublicRoute('/login/confirm_email', <ValidateEmailPage />),
    PublicRoute('/login/reset_password', <Login />),
    PublicRoute('/subscriptions/handle', <ValidateSubscriptionPage />),
])

export default function AppRoutes() {
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    )
}

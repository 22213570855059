import axios, {AxiosError, AxiosInstance} from 'axios'
import {IAuthData} from '../types/user'
import {CookiesService} from './Cookie.service'
import {detectaCodigoMalicioso} from '../utils/sanitize'

export class AuthApi {
    private cookiesService: CookiesService
    private api: AxiosInstance

    constructor() {
        this.cookiesService = new CookiesService()
        this.api = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/login`,
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_SECRET}`,
            },
        })
    }

    async login(username: string, password: string): Promise<IAuthData> {
        try {
            if (
                detectaCodigoMalicioso(username) ||
                detectaCodigoMalicioso(password)
            ) {
                throw new Error('Acesso negado')
            }
            const res: {data: IAuthData} = await this.api.post(
                '/',
                {
                    username,
                    password,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            this.cookiesService.saveAuthData(res.data)
            return res.data
        } catch (error: any) {
            console.log('Login error: ', error)
            if (error.response?.status === 403) {
                throw 403
            } else if (error.message === 'Network Error' || !error.response) {
                throw 'Servidor indisponível. Tente novamente mais tarde.'
            } else {
                throw 'Usuário ou senha incorretos.'
            }
        }
    }

    async sendEmailVerification(email: string) {
        try {
            const response = await this.api.get(
                `/send_email_confirmation/${email}`
            )
            return
        } catch (error) {
            console.log(error)
            throw 'Não foi possível enviar a confirmação de email. Tente novamente.'
        }
    }
    async sendEmailForResetPassword(email: string) {
        try {
            const response = await this.api.get(`/reset_password/${email}`)
            return
        } catch (error) {
            console.log(error)
            throw 'Não foi possível enviar o link para redefinição de senha. Tente novamente.'
        }
    }
    async sendTokenToResetPassword(token: string, password: string) {
        try {
            const response = await this.api.put(
                `/reset_password/${token}`,
                {
                    username: 'teste',
                    password,
                },
                {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            return
        } catch (error) {
            console.log(error)
            throw 'Não foi possível redefinir sua senha. Tente novamente.'
        }
    }

    async signUp(
        first_name: string,
        last_name: string,
        email: string,
        password: string
    ): Promise<void> {
        try {
            if (
                detectaCodigoMalicioso(first_name) ||
                detectaCodigoMalicioso(last_name) ||
                detectaCodigoMalicioso(email) ||
                detectaCodigoMalicioso(password)
            ) {
                throw new Error('Acesso negado.')
            }
            const res: {
                data: {
                    created_at: Date
                    guid: string
                    first_name: string
                    last_name: string
                    email: string
                    last_session: Date
                    modified_at: Date
                    is_active: boolean
                }
            } = await this.api.post(
                '/create',
                {
                    first_name,
                    last_name,
                    email,
                    password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            await this.login(email, password)
        } catch (error: any) {
            console.log('SignUp Error: ', error)
            if (error === 403) {
                throw error
            } else {
                throw 'Erro ao cadastrar novo usuário.'
            }
        }
    }

    async confirmEmail(token: string) {
        try {
            const res: {data: IAuthData} = await this.api.get(
                `/confirm_email/${token}`
            )
            this.cookiesService.saveAuthData(res.data)
            return res.data
        } catch (error: any) {
            console.log('erro ----->', error.data.detail)
            console.log('erro ----->', error.detail)
            throw 'Erro ao confirmar email. Tente novamente'
        }
    }

    editUser(
        firstName: string,
        lastName: string,
        phone: string,
        address: string,
        code: string
    ) {
        alert('Em breve')
    }

    editEmail(email: string) {
        alert('Em breve')
    }

    async doRefreshToken(): Promise<IAuthData> {
        try {
            const refreshToken =
                this.cookiesService.getAuthData()?.refresh_token

            if (!refreshToken) {
                this.cookiesService.removeAuthData()
                this.cookiesService.removeAssistant()
                throw (window.location.href = '/login')
            }
            const response = await this.api.get(`/refresh/${refreshToken}`)

            const newAuthData: IAuthData = response.data
            this.cookiesService.saveAuthData(newAuthData)

            return newAuthData
        } catch (error) {
            this.cookiesService.removeAuthData()
            this.cookiesService.removeAssistant()
            throw (window.location.href = '/login')
        }
    }
}

import styled from 'styled-components'
import Background from '../../../../src/assets/background.svg'
import {useContext, useEffect, useMemo, useState} from 'react'
import {Typography, Button, Modal, Box} from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import {AuthContext} from '../../../contexts/auth'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined'
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import {AssistantApi} from '../../../services/AssistantApi.service'

const Page = styled.div`
    height: 100vh;
    margin-top: 0;
    padding: 64px 48px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;
    flex: 1;
`

const Header = styled.div`
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ButtonGroup = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 16px;
`

const PerfilContainer = styled.div`
    max-width: 1000px;
    width: 742px;
    height: 450px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 14px;
    margin-bottom: 24px;
    align-self: center;
`

const PerfilHeader = styled.div`
    display: flex;
    height: 138px;
    align-items: center;
`

const PerfilHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 742px;
    height: 116px;
    align-items: center;
    gap: 16px;
    align-self: center;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 14px;
`

const PerfilContent = styled.div`
    display: flex;
    height: 80px;
    flex-direction: row;
    border-top: 0.7px solid #e4e4e4;
    align-items: center;
    gap: 16px;
`
const PerfilBottom = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    border-top: 0.7px solid #e4e4e4;
`

export default function EditFForm() {
    const [activeTab, setActiveTab] = useState('perfil')
    const {userInfo} = useContext(AuthContext)
    const [name, setName] = useState(userInfo?.first_name || '')
    const [lastName, setLastName] = useState(userInfo?.last_name || '')
    const [email, setEmail] = useState('')
    const [openModalEmail, setOpenModalEmail] = useState(false)
    const [openModalPassword, setOpenModalPassword] = useState(false)
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const api = useMemo(() => new AssistantApi(), [])

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.first_name)
            setLastName(userInfo.last_name)
        }
    }, [userInfo])

    const handleClose = () => {
        setOpenModalEmail(false)
        setOpenModalPassword(false)
        setOpenModalRemove(false)
    }

    async function inviteUser(email: string) {
        api.inviteUserOrganization(email)
    }

    async function removeUser(user_guid: string) {
        api.removeUserOrganization(user_guid)
    }

    return (
        <Page>
            <Header>
                <div>
                    <Typography variant="h4">Configurações</Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 400,
                            fontSize: '18px',
                            color: '#707070',
                            marginTop: '8px',
                        }}
                    >
                        Atualize as suas configurações de conta
                    </Typography>

                    <ButtonGroup>
                        <Button
                            style={{
                                gap: '8px',

                                color:
                                    activeTab === 'perfil'
                                        ? '#ABABAB'
                                        : 'black',
                                borderColor:
                                    activeTab === 'perfil'
                                        ? '#E4E4E4'
                                        : '#ABABAB',

                                fontSize: '14px',
                                fontWeight: 400,
                                fontFamily: 'Roboto',
                                backgroundColor: '#FFFFFF',
                            }}
                            variant={
                                activeTab === 'perfil'
                                    ? 'contained'
                                    : 'outlined'
                            }
                            disabled={activeTab === 'perfil'}
                            onClick={() => setActiveTab('perfil')}
                            sx={{textTransform: 'none'}}
                        >
                            <AccountCircleOutlinedIcon />
                            Meu perfil
                        </Button>
                        {!userInfo?.org_admin && (
                            <Button
                                style={{
                                    gap: '8px',
                                    color:
                                        activeTab === 'permissoes'
                                            ? '#ABABAB'
                                            : 'black',
                                    borderColor:
                                        activeTab === 'permissoes'
                                            ? '#E4E4E4'
                                            : '#ABABAB',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    fontFamily: 'Roboto',
                                    backgroundColor: '#FFFFFF',
                                }}
                                variant={
                                    activeTab === 'permissoes'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                disabled={activeTab === 'permissoes'}
                                onClick={() => setActiveTab('permissoes')}
                                sx={{textTransform: 'none'}}
                            >
                                <GroupsIcon /> Permissões de usuários
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </Header>

            {activeTab === 'perfil' && (
                <PerfilContainer>
                    <PerfilHeader>
                        <h4
                            style={{
                                marginLeft: '24px',
                                fontSize: '24px',
                                fontWeight: 400,
                                fontFamily: 'Roboto',
                                color: 'black',
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '64px',
                                    height: '64px',
                                    borderRadius: '50%',
                                    backgroundColor: '#8DBADF2B',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '16px',
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: 'black',
                                        fontWeight: 500,
                                    }}
                                >
                                    {userInfo?.first_name[0]}
                                    {userInfo?.last_name[0]}
                                </Typography>
                            </div>
                            {userInfo?.first_name}
                        </h4>
                    </PerfilHeader>
                    <PerfilContent>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '18px',
                                color: 'black',
                                marginRight: '16px',
                                alignSelf: 'center',
                                marginLeft: '24px',
                            }}
                        >
                            Nome
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginRight: '24px',
                            }}
                        >
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                style={{
                                    height: '40px',
                                    width: '230px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #e4e4e4',
                                }}
                            />
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                style={{
                                    height: '40px',
                                    width: '230px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #e4e4e4',
                                }}
                            />
                        </div>
                    </PerfilContent>
                    <PerfilContent>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '18px',
                                color: 'black',
                                marginRight: '16px',
                                alignSelf: 'center',
                                marginLeft: '24px',
                            }}
                        >
                            E-mail
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end',
                                width: '80%',
                                marginLeft: '20px',
                            }}
                        >
                            <input
                                type="text"
                                value={userInfo?.email}
                                style={{
                                    height: '40px',
                                    width: '478px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #e4e4e4',
                                }}
                            />
                        </div>
                    </PerfilContent>
                    <PerfilContent>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 400,
                                fontSize: '18px',
                                color: 'black',
                                marginRight: '16px',
                                alignSelf: 'center',
                                marginLeft: '24px',
                            }}
                        >
                            Senha
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'flex-end',
                                width: '80%',
                                marginLeft: '20px',
                            }}
                        >
                            <input
                                type="text"
                                style={{
                                    height: '40px',
                                    width: '478px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #e4e4e4',
                                }}
                            />
                        </div>
                    </PerfilContent>
                    <PerfilBottom>
                        <Button
                            variant="outlined"
                            style={{
                                backgroundColor: '#FFFFFF',
                                color: 'black',
                                borderColor: '#D9D9D9',
                                width: '111px',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                // Handle cancel action
                            }}
                            sx={{marginRight: '16px'}}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: '#012441',
                                color: 'white',
                                width: '152px',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                // Handle save action
                            }}
                            sx={{marginRight: '24px'}}
                        >
                            Salvar
                        </Button>
                    </PerfilBottom>
                </PerfilContainer>
            )}
            {activeTab === 'permissoes' && (
                <>
                    <PerfilHeaderContainer style={{marginTop: '24px'}}>
                        <PerfilHeader>
                            <h4
                                style={{
                                    marginLeft: '24px',
                                    fontSize: '24px',
                                    fontWeight: 400,
                                    fontFamily: 'Roboto',
                                    color: 'black',
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        borderRadius: '50%',
                                        backgroundColor: '#8DBADF2B',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '16px',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: 'black',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {userInfo?.first_name[0]}
                                        {userInfo?.last_name[0]}
                                    </Typography>
                                </div>
                                {userInfo?.first_name}
                            </h4>
                        </PerfilHeader>
                        <span
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginRight: '24px',
                            }}
                            onClick={() => {
                                setActiveTab('perfil')
                            }}
                        >
                            <DrawOutlinedIcon />
                            Editar perfil
                        </span>
                    </PerfilHeaderContainer>
                    <PerfilContainer style={{marginTop: '24px'}}>
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                marginTop: '16px',
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Nome
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        E-mail
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Mensagens
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Documentos
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    ></th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Ações
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                        }}
                                    >
                                        {userInfo?.first_name}{' '}
                                        {userInfo?.last_name}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                        }}
                                    >
                                        {userInfo?.email}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                        }}
                                    >
                                        {/* Add messages data here */}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                            padding: '8px',
                                        }}
                                    >
                                        {/* Add documents data here */}
                                    </td>
                                    <td
                                        style={{
                                            borderBottom: '1px solid #e4e4e4',
                                        }}
                                    >
                                        <PersonRemoveOutlinedIcon
                                            style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setOpenModalRemove(true)
                                            }}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            borderBottom: '1px solid #e4e4e4',
                                            paddingTop: '9px',
                                        }}
                                    >
                                        <LockResetOutlinedIcon
                                            style={{
                                                color: '#3394EF',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setOpenModalPassword(true)
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <PerfilBottom style={{marginTop: '37%'}}>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#012441',
                                    color: 'white',
                                    width: '210px',
                                    textTransform: 'none',
                                    marginRight: '24px',
                                }}
                                onClick={() => {
                                    setOpenModalEmail(true)
                                }}
                            >
                                <PersonAddAltOutlinedIcon /> Adicionar novo
                                membro
                            </Button>
                        </PerfilBottom>
                    </PerfilContainer>
                </>
            )}

            <Modal
                open={openModalEmail}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{mb: 2}}
                    >
                        Insira o e-mail do usuário
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        style={{
                            color: '#707070',
                            fontSize: '16px',
                            fontWeight: 400,
                        }}
                        sx={{mb: 2}}
                    >
                        Digite o e-mail abaixo para enviar o convite
                    </Typography>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="E-mail"
                        style={{
                            width: '100%',
                            padding: '8px',
                            marginBottom: '16px',
                            borderRadius: '4px',
                            border: '1px solid #e4e4e4',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{
                                textTransform: 'none',
                                border: 'none',
                                color: 'red',
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'none',
                                border: 'none',
                            }}
                            onClick={() => {
                                inviteUser(email)
                            }}
                        >
                            Enviar
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openModalRemove}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{mb: 2}}
                    >
                        Deseja remover o usuário?
                    </Typography>
                    <Typography
                        style={{
                            color: '#707070',
                            fontSize: '16px',
                            fontWeight: 400,
                        }}
                        id="modal-modal-description"
                        sx={{mb: 2}}
                    >
                        Remover o usuário {userInfo?.email}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{
                                textTransform: 'none',
                                color: 'red',
                                border: 'none',
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'none',
                                border: 'none',
                            }}
                            onClick={() => {
                                removeUser(userInfo?.guid)
                            }}
                        >
                            Sim, Remover
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openModalPassword}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',

                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{mb: 2}}
                    >
                        Mudar senha do usuário
                    </Typography>
                    <Typography
                        style={{
                            color: '#707070',
                            fontSize: '16px',
                            fontWeight: 400,
                        }}
                        id="modal-modal-description"
                        sx={{mb: 2}}
                    >
                        Digite a nova senha do usuário
                    </Typography>
                    <input
                        type="password"
                        placeholder="senha"
                        style={{
                            width: '100%',
                            padding: '8px',
                            marginBottom: '16px',
                            borderRadius: '4px',
                            border: '1px solid #e4e4e4',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{
                                textTransform: 'none',
                                border: 'none',
                                color: 'red',
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'none',
                                border: 'none',
                            }}
                            onClick={() => {
                                // Handle send action
                            }}
                        >
                            Continuar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Page>
    )
}

import styled from 'styled-components'
import {COLORS} from '../utils/colors'
import Tooltip, {StyledTooltip} from './tooltip'
import {useLocation} from 'react-router-dom'

interface ButtonProps {
    SVGIcon: string
    onclick: () => any
    IconAltText: string
    text: string
    endText?: string
    disabled?: boolean
    tooltip?: string
    customStyle?: React.CSSProperties
    customIconStyle?: React.CSSProperties
    selectedButton?: boolean
    align?: 'left' | 'center'
}

const StyledButton = styled.button<{
    customStyle?: React.CSSProperties
    selectedButton?: boolean
    pathname: string
    align?: 'left' | 'center'
}>`
    display: flex;
    padding: 13.2px 13px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;

    background-color: ${({selectedButton, pathname}) => {
        if (selectedButton) {
            return pathname === '/' ? '#3ACDB41A' : '#8DBADF2B'
        }
        return COLORS.branco
    }};

    border-radius: 4px;
    width: 80%;
    max-width: 200px;
    border: 1px solid ${COLORS.cinzaEscuro};
    align-self: center;
    transition: background-color 0.3s ease;
    gap: 10px;

    ${({customStyle}) =>
        customStyle &&
        `
        ${Object.entries(customStyle)
            .map(([key, value]) => `${key}: ${value};`)
            .join('')}
    `}

    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`

const Icon = styled.img<{customIconStyle?: React.CSSProperties}>`
    width: 25px;
    height: 25px;
    flex-shrink: 0;

    ${({customIconStyle}) =>
        customIconStyle &&
        `
        ${Object.entries(customIconStyle)
            .map(([key, value]) => `${key}: ${value};`)
            .join('')}
    `}
`

const MainText = styled.span<{align?: 'left' | 'center'}>`
    flex-grow: 1;
    text-align: ${({align}) => (align === 'left' ? 'left' : 'center')};
`

const EndText = styled.span`
    margin-left: auto;
`

export default function Button({
    SVGIcon,
    IconAltText,
    onclick,
    text,
    endText,
    disabled = false,
    tooltip,
    customStyle,
    customIconStyle,
    selectedButton,
    align = 'center',
}: ButtonProps) {
    const {pathname} = useLocation()

    function handleClick(e: any) {
        e.preventDefault()
        onclick()
    }

    return (
        <StyledButton
            selectedButton={selectedButton}
            pathname={pathname}
            disabled={disabled}
            onClick={handleClick}
            customStyle={customStyle}
            align={align}
        >
            {!!tooltip && <Tooltip left="20px" text={tooltip} top="-15px" />}
            <Icon
                src={SVGIcon}
                alt={IconAltText}
                customIconStyle={customIconStyle}
            />
            <MainText align={align}>{text}</MainText>
            {endText && <EndText>{endText}</EndText>}
        </StyledButton>
    )
}

import {messageType} from '../../../../../types/message'
import userIcon from '../../../../../assets/guru.png'
import styled from 'styled-components'
import {COLORS} from '../../../../../utils/colors'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Tools from './tools'

interface MessageProps {
    message: messageType
    arrayLength: number
    index: number
}

const MessageContainer = styled.section<{isUser: boolean}>`
    display: flex;
    align-items: flex-start;

    margin: 20px 0;
    border-radius: 20px;
    background-color: ${({isUser}) =>
        isUser ? '#ffffff' : 'rgba(0, 0, 0, 0)'};
    padding: 15px;
    max-width: 627px;
`

const Identity = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 15px;

    & p {
        font-size: 12px;
        font-weight: 700;
        color: ${COLORS.cinzaEscuro};
    }

    & img {
        width: 44px;
        height: 44px;
    }
`

const MarkdownContent = styled.div<{isUser: boolean}>`
    font-size: 17px;
    color: ${({isUser}) => (isUser ? '#000000' : '#000000')};
    word-wrap: break-word;
    line-height: 1.6;
    text-align: ${({isUser}) => (isUser ? 'right' : 'left')};
    overflow-x: auto;

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        color: ${COLORS.cinzaEscuro};
        background-color: #77dccb;
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f4f4f4;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }
`

export default function Message({arrayLength, message, index}: MessageProps) {
    const isUser = !!message.author_id
    const lastAssistantMessage = arrayLength - 1 === index

    return (
        <>
            {!isUser && (
                <Identity>
                    <img src={userIcon} alt="Ícone de usuário" />
                </Identity>
            )}
            <MessageContainer isUser={isUser}>
                <MarkdownContent isUser={isUser}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {message.content}
                    </Markdown>
                    {!isUser && (
                        <Tools
                            lastAssistantMessage={lastAssistantMessage}
                            message={message}
                        />
                    )}
                </MarkdownContent>
            </MessageContainer>
        </>
    )
}

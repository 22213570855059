import styled from 'styled-components'
import {useContext, useMemo, useState} from 'react'
import {AuthContext} from '../../../../contexts/auth'
import SubmitButton from '../../../../globalComponents/SubmitButton'
import {COLORS} from '../../../../utils/colors'
import useError from '../../../../globalComponents/useError'
import {useLoading} from '../../../../globalComponents/useLoading'
import FormField from '../../../../globalComponents/formField'
import Divisor from './divisor'
import CreatePassword from './createPassword'
import {pageType} from '../../../../types/loginPages'
import PDFModal from '../../../../globalComponents/PDFModal'
import {CookiesService} from '../../../../services/Cookie.service'
import testFile from '../../../../assets/files/[v1] Política de Testes - Fase de Testes.pdf'
import googleIcon from '../../../../assets/googleIcon.png'
import facebookIcon from '../../../../assets/facebookIcon.png'
import VerifyEmail from './verifyEmail'
import logoLogin from '../../../../assets/logoLogin.svg'
import returnIcon from '../../../../assets/Vector.svg'
import ResetPassword from './resetPassword'

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    gap: 20px;

    width: 560px;
    background-color: ${COLORS.branco};
`

const Logo = styled.img``

const Title = styled.h1`
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90deg, #3ffe81, #3394ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
`

const Subtitle = styled.h2`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #838383;
`

const GoogleButton = styled.button`
    font-size: 13px;
    color: black;
    cursor: pointer;
    width: 100%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`
const SelectionButton = styled.button<{selected: boolean; primeiro: boolean}>`
    padding: 10px 0;
    width: 250px;
    background-color: ${COLORS.azul};
    color: ${COLORS.branco};
    opacity: ${({selected}) => (selected ? '1' : '0.25')};
    font-weight: 700;
    font-size: 13px;
    border-radius: ${({primeiro}) =>
        primeiro ? '10px 0 0 10px' : '0 10px 10px 0'};
    cursor: pointer;
`

const HeaderLogin = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const NameContainer = styled.div`
    display: flex;
    gap: 10px;
`

const Body = styled.div`
    width: 50%;
`
const GoToResetPassword = styled.a`
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-size: 13px;
    color: black;
    text-decoration: none;
    cursor: pointer;
`

const ButtonForm = styled.span`
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-size: 13px;
    color: #838383;
    text-decoration: none;
`

const CheckboxField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin: 10px 0;
    font-size: 15px;
    color: ${COLORS.cinzaEscuro};
    border-radius: 8px 8px 0 0;
    width: 80%;
    padding: 10px;

    position: fixed;
    top: 0;
    background-color: ${COLORS.cinzaClaro};
    z-index: 99999;

    & p {
        font-size: 15px;
    }
    & input {
        font-size: 30px;
        width: 30px;
    }

    & strong {
        text-decoration: underline;
        cursor: pointer;
    }

    & div {
        width: 300px;
    }
`

interface LoginFormProps {
    flag?: 'gratuito' | 'plano1' | 'plano2' | any
}

export default function LoginForm({flag}: LoginFormProps) {
    const {login, signUp} = useContext(AuthContext)
    const {Spinner, setIsLoading} = useLoading()
    const [page, setPage] = useState<pageType>(flag ? 'Cadastre-se' : 'Entrar')
    const {ErrorComponent, showError} = useError()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [pdf, setPdf] = useState(null)
    const [checked, setChecked] = useState(false)

    const search = window.location.search
    const token = search.slice(7, search.length)

    const cookieService = useMemo(() => new CookiesService(), [])

    // function doAgreement() {
    //     if (!cookieService.getCheckboxStatus()) {
    //         setPdf(testFile)
    //         return
    //     }
    //     handleLogin()
    // }

    async function handleLogin() {
        if (!email || !password) {
            return
        }
        setIsLoading(true)
        try {
            await login(email, password)
            cookieService.saveFlag(flag)
            // cookieService.setCheckboxStatus()
        } catch (error: any) {
            if (error === 403) {
                setPage('Verificar Email')
            }
            setPdf(null)
            setChecked(false)
            showError(error)
        } finally {
            setIsLoading(false)
        }
    }
    async function handleSignUp() {
        if (!firstName || !lastName || !email || !password) {
            return
        }
        setIsLoading(true)
        try {
            await signUp(firstName, lastName, email, password)
            cookieService.saveFlag(flag)
        } catch (error: any) {
            if (error === 403) {
                setPage('Verificar Email')
            }
            showError(error)
        } finally {
            setIsLoading(false)
        }
    }

    function handleGoToCreatePassword() {
        if (!firstName || !lastName || !email) {
            return
        }
        setPage('Criar Senha')
    }

    if (token) {
        return <ResetPassword />
    }

    return (
        <>
            {!!pdf && (
                <>
                    <CheckboxField>
                        <p>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => setChecked((prev) => !prev)}
                            />
                            Declaro que li e concordo com a{' '}
                            <strong onClick={() => setPdf(testFile)}>
                                Política de Testes
                            </strong>
                            .
                        </p>
                        <div>
                            <SubmitButton
                                disabled={!checked}
                                onclick={handleLogin}
                                label="Confirmar e Fazer Login"
                            />
                        </div>
                    </CheckboxField>
                    <PDFModal item={pdf} setModal={setPdf} />
                </>
            )}
            <Form>
                {(page === 'Entrar' || page === 'Cadastre-se') && (
                    <>
                        {page === 'Entrar' && (
                            <HeaderLogin>
                                <Logo src={logoLogin} />
                                <Title>Bem-vindo de volta!</Title>
                                <Subtitle>
                                    Digite seu e-mail e senha para continuar.
                                </Subtitle>
                            </HeaderLogin>
                        )}

                        {page === 'Cadastre-se' && (
                            <HeaderLogin>
                                <Title>Digite seus dados</Title>
                                <Subtitle>
                                    Digite seus dados para continuar.
                                </Subtitle>
                            </HeaderLogin>
                        )}

                        <Body>
                            {/* <GoogleButton type="button">
                                {page} com o Google
                            </GoogleButton> */}
                            {/* <Divisor /> */}

                            {page === 'Cadastre-se' && (
                                <>
                                    <NameContainer>
                                        <FormField
                                            autofocus
                                            submit={handleGoToCreatePassword}
                                            inputType="text"
                                            state={firstName}
                                            setState={setFirstName}
                                            label="Nome"
                                        />
                                        <FormField
                                            submit={handleGoToCreatePassword}
                                            inputType="text"
                                            state={lastName}
                                            setState={setLastName}
                                            label="Sobrenome"
                                        />
                                    </NameContainer>
                                    <FormField
                                        submit={handleGoToCreatePassword}
                                        inputType="email"
                                        state={email}
                                        setState={setEmail}
                                        label="Endereço de e-mail"
                                    />
                                    <SubmitButton
                                        disabled={
                                            !firstName || !lastName || !email
                                        }
                                        label="Continuar"
                                        onclick={handleGoToCreatePassword}
                                    />
                                    <ButtonForm
                                        onClick={() => setPage('Entrar')}
                                    >
                                        <img
                                            src={returnIcon}
                                            alt="Retornar para Login"
                                        />
                                        Voltar para{' '}
                                        <strong
                                            style={{
                                                cursor: 'pointer',
                                                color: 'black',
                                            }}
                                        >
                                            Login
                                        </strong>
                                    </ButtonForm>
                                    {ErrorComponent}
                                </>
                            )}

                            {page === 'Entrar' && (
                                <>
                                    <FormField
                                        autofocus
                                        submit={handleLogin}
                                        inputType="email"
                                        state={email}
                                        setState={setEmail}
                                        label="E-mail"
                                    />
                                    <FormField
                                        submit={handleLogin}
                                        inputType="password"
                                        state={password}
                                        setState={setPassword}
                                        label="Senha"
                                    />
                                    <GoToResetPassword
                                        onClick={() =>
                                            setPage('Esqueci minha senha')
                                        }
                                    >
                                        Esqueceu a senha?
                                    </GoToResetPassword>
                                    <SubmitButton
                                        disabled={!email || !password}
                                        label={page}
                                        onclick={handleLogin}
                                    />
                                    {/* <Divisor />
                                    <GoogleButton type="button">
                                        <img
                                            src={googleIcon}
                                            alt="Google Icon"
                                            style={{
                                                marginRight: '8px',
                                            }}
                                        />
                                        Continue com Google
                                    </GoogleButton>
                                    <GoogleButton
                                        style={{marginTop: '8px'}}
                                        type="button"
                                    >
                                        <img
                                            src={facebookIcon}
                                            alt="Facebook Icon"
                                            style={{marginRight: '8px'}}
                                        />
                                        Continue com Facebook
                                    </GoogleButton> */}
                                    <ButtonForm>
                                        Não tem conta?{' '}
                                        <strong
                                            style={{
                                                cursor: 'pointer',
                                                color: 'black',
                                            }}
                                            onClick={() =>
                                                setPage('Cadastre-se')
                                            }
                                        >
                                            Cadastre-se aqui
                                        </strong>
                                    </ButtonForm>

                                    {ErrorComponent}
                                </>
                            )}
                        </Body>
                    </>
                )}

                {page === 'Criar Senha' && (
                    <>
                        <HeaderLogin>
                            <Title>Crie sua senha</Title>
                            <Subtitle>Com no mínimo 8 caracteres.</Subtitle>
                        </HeaderLogin>
                        <CreatePassword
                            password={password}
                            setPassword={setPassword}
                            handleSignUp={handleSignUp}
                            setPage={setPage}
                        />

                        {ErrorComponent}
                    </>
                )}

                {page === 'Esqueci minha senha' && (
                    <>
                        <HeaderLogin>
                            <Title>Esqueceu sua senha?</Title>
                            <Subtitle>
                                Digite seu e-mail para recuperar a senha.
                            </Subtitle>
                        </HeaderLogin>
                        <ResetPassword />
                        <ButtonForm onClick={() => setPage('Entrar')}>
                            <img src={returnIcon} alt="Retornar para Login" />
                            Voltar para{' '}
                            <strong
                                style={{
                                    cursor: 'pointer',
                                    color: 'black',
                                }}
                            >
                                Login
                            </strong>
                        </ButtonForm>
                        {ErrorComponent}
                    </>
                )}

                {page === 'Verificar Email' && <VerifyEmail email={email} />}
                <Spinner></Spinner>
            </Form>
        </>
    )
}

import styled from 'styled-components'
import EditForm from './components/form'
import logoTransparente from '../../assets/logoTransparente.png'
import HistoryProvider from '../../contexts/history'
import LeftBar from '../home/components/leftBar'

const Page = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

export default function UserConfigs() {
    return (
        <Page>
            <HistoryProvider>
                <LeftBar />
                <EditForm />
            </HistoryProvider>
        </Page>
    )
}

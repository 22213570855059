import styled from 'styled-components'
import logoTransparente from '../../assets/logo_fundo_azul.svg'
import {COLORS} from '../../utils/colors'
import Cards from './cards'
import HistoryProvider from '../../contexts/history'
import LeftBar from '../home/components/leftBar'

const Page = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Changed from center to flex-start */
    align-items: left;
    align-self: flex-end;
    background-color: rgba(0, 0, 0, 0);
    padding: 20px 0;
    font-size: 12px;
    color: #7c7c7c;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    margin-left: 20vw;

    @media (max-width: 1590px) {
        margin-left: 25vw;
    }

    @media (max-width: 1120px) {
        margin-left: 30vw;
    }
`

export default function Plans() {
    return (
        <Page>
            <HistoryProvider>
                <LeftBar />
                <Cards />
            </HistoryProvider>
            <Footer>powered by Schief.ai</Footer>
        </Page>
    )
}

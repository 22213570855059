import React, {useContext, useState, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {
    Grid2,
    CardContent,
    Typography,
    Modal,
    Box,
    Button,
    Select,
    MenuItem,
    TextField,
    Tooltip,
} from '@mui/material'
import {Await, useNavigate} from 'react-router-dom'
import {AuthContext} from '../../../contexts/auth'
import HistoryProvider from '../../../contexts/history'
import {HistoryContext} from '../../../contexts/history'
import ProjetoVazio from '../../../assets/projeto-vazio.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import EditIcon from '@mui/icons-material/Edit'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import {AssistantApi} from '../../../services/AssistantApi.service'
import {project} from '../../../types/project'
import {QuestionAnswer} from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import {toast} from 'react-toastify'

const Page = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    scroll-behavior: smooth;
    flex: 1;
`

const Container = styled.div`
    max-height: calc(100vh - 100px);
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    overflow-y: auto;
    box-sizing: border-box;
`

const CardContainer = styled.div`
    width: 221px;
    height: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
`

const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 20px;
    color: #000f35;
`

const CardBody = styled(CardContent)`
    min-height: 210px;
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer;
    align-items: flex-start;
    overflow: hidden;
`

const TruncatedTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-height: 60px;
    word-break: break-word;
`

const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 20px;
`

const SelectedSchiefMessage = styled.span`
    font-size: 38px;
    text-align: start;
    top: 48px;
    margin-left: 110px;
`

const ModalContent = styled(Box)`
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 320px;
    height: 232px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-left: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 18px;
    color: #707070;
`

export default function Projetos() {
    const {
        projectArray,
        getNextQuestion,
        postProject,
        setQuestionArray,
        questionArray,
        limits,
    } = useContext(HistoryContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState('ETP')
    const [documentName, setDocumentName] = useState('')
    const [projects, setProjects] = useState<project[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const api = useMemo(() => new AssistantApi(), [])
    const [openAlertErro, setOpenAlertErro] = React.useState(false)

    useEffect(() => {
        if (projectArray.length > 0) {
            setProjects(projectArray)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }, [projectArray])

    const handleOpenModal = () => {
        setModalOpen(true)
        // setOpenAlertErro(true)
        // if (limits.usages.projects.current <= limits.usages.projects.max) {
        //     return
        // }
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setDocumentName('')
        setSelectedDocumentType('')
    }

    useEffect(() => {
        console.log('questionArray atualizado', questionArray)
        if (questionArray.length > 0) {
            navigate('/gerador')
        }
    }, [questionArray])

    const handleProcessing = async (project_id: string) => {
        console.log('Processing clicked for project:', project_id)
        localStorage.setItem('projectId', JSON.stringify(project_id))
        navigate('/espera')
    }

    const handleEdit = async (project_id: string, project_name: string) => {
        console.log('Edit clicked for project:', project_id)
        const res = await api.getNextQuestion(project_id)
        localStorage.setItem('questionArray', JSON.stringify([res]))
        localStorage.setItem('projectId', JSON.stringify(project_id))
        localStorage.setItem('projectName', JSON.stringify(project_name))

        setQuestionArray([res])
    }

    const handleConfirm = async () => {
        try {
            const projectData = {
                created_at: new Date().toISOString(),
                name: documentName,
                description: `${documentName} - ${selectedDocumentType}`,
                updated_at: new Date().toISOString(),
                start_date: new Date().toISOString(),
                end_date: new Date().toISOString(),
                is_active: true,
                project_type_id: '899704fd-10be-4aac-8b36-cafccc3f0f0a',
                status: 'INCOMPLETE',
            }

            const res = await api.postProject(projectData)
            console.log('handle', res)
            localStorage.setItem('questionArray', JSON.stringify([res]))
            localStorage.setItem('projectName', JSON.stringify(documentName))
            localStorage.setItem('projectId', JSON.stringify(res.project_id))
            setQuestionArray([res])

            handleCloseModal()
        } catch (error) {
            console.error('Error creating project:', error)
        }
    }

    return (
        <Page>
            <SelectedSchiefMessage>Meus documentos</SelectedSchiefMessage>

            {isLoading === true || projectArray.length === 0 ? (
                <div
                    style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        height: '80%',
                        overflowY: 'auto',
                        maxWidth: '90%',
                        marginTop: '10%',
                    }}
                >
                    <img
                        src={ProjetoVazio}
                        alt="Projeto Vazio"
                        style={{maxWidth: '100%', height: 'auto'}}
                    />
                    <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{marginTop: '-40px'}}
                    >
                        Ops, parece que você ainda não criou um documento
                    </Typography>
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        style={{marginTop: '10px'}}
                    >
                        Aqui você pode ver a listagem, status e criar novos
                        documentos.
                    </Typography>
                    <Button
                        style={{
                            marginTop: '20px',
                            color: '#fff',
                        }}
                        variant="contained"
                        onClick={handleOpenModal}
                    >
                        Criar novo Documento
                    </Button>
                </div>
            ) : (
                <Container>
                    <Header>
                        Aqui você pode ver a listagem, status e criar novos
                        documentos.
                    </Header>
                    <Grid2
                        style={{overflow: 'auto'}}
                        container
                        rowSpacing={2}
                        columnSpacing={3}
                    >
                        <CardContainer
                            style={{
                                backgroundColor: '#8DBADF2B',
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                            }}
                            onClick={handleOpenModal}
                        >
                            <AddCircleOutlineIcon
                                style={{color: '#012441', marginBottom: '8px'}}
                            />
                            <span
                                style={{
                                    width: '82px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#012441',
                                }}
                            >
                                Criar novo documento
                            </span>
                        </CardContainer>
                        {projectArray.map((project) => (
                            <Grid2 key={project.id} size="auto">
                                <CardContainer
                                    onClick={() => {
                                        if (project.status === 'INCOMPLETE') {
                                            handleEdit(project.id, project.name)
                                        } else if (
                                            project.status === 'PROCESSING'
                                        ) {
                                            handleProcessing(project.id)
                                        } else if (
                                            project.status === 'FINISHED' &&
                                            project.doc_url
                                        ) {
                                            window.open(
                                                project.doc_url,
                                                '_blank'
                                            )
                                        } else {
                                            console.log(
                                                'Download clicked for project:',
                                                project.id
                                            )
                                        }
                                    }}
                                >
                                    <CardHead>
                                        <Tooltip title={project.name}>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                fontWeight="bold"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '180px',
                                                }}
                                            >
                                                {project.name}
                                            </Typography>
                                        </Tooltip>
                                    </CardHead>
                                    <CardBody>
                                        <Tooltip title={project.description}>
                                            <TruncatedTypography
                                                style={{fontSize: '13px'}}
                                                variant="caption"
                                                color="textSecondary"
                                            >
                                                {project.description}
                                            </TruncatedTypography>
                                        </Tooltip>
                                    </CardBody>
                                    <CardFooter>
                                        {project.status === 'FINISHED' ? (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            '#007AFF',
                                                        padding: '6px',
                                                        borderRadius: '100px',
                                                    }}
                                                >
                                                    Pronto
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#1976D2',
                                                        fontSize: '14px',
                                                        marginLeft: '21px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <FileDownloadIcon />{' '}
                                                    Download
                                                </Typography>
                                            </>
                                        ) : project.status === 'INCOMPLETE' ? (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            '#FFA500',
                                                        padding: '6px',
                                                        borderRadius: '100px',
                                                    }}
                                                >
                                                    Incompleto
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#FFA500',
                                                        fontSize: '14px',
                                                        marginLeft: '21px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <EditIcon /> Continuar
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            '#EF6C00',
                                                        padding: '6px',
                                                        borderRadius: '100px',
                                                    }}
                                                >
                                                    Pendente
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#EF6C00',
                                                        fontSize: '14px',
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <AutorenewIcon />{' '}
                                                    Processando
                                                </Typography>
                                            </>
                                        )}
                                    </CardFooter>
                                </CardContainer>
                            </Grid2>
                        ))}
                    </Grid2>
                </Container>
            )}

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                onKeyDown={(event) => {
                    if (
                        event.key === 'Enter' &&
                        selectedDocumentType &&
                        documentName
                    ) {
                        handleConfirm()
                    }
                }}
            >
                <ModalContent>
                    <TextField
                        label="Nome do Documento"
                        variant="outlined"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        fullWidth
                        autoFocus
                    />
                    <Select
                        value={selectedDocumentType}
                        onChange={(e) =>
                            setSelectedDocumentType(e.target.value)
                        }
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Tipo do Documento
                        </MenuItem>
                        <MenuItem value="ETP">ETP</MenuItem>
                    </Select>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '16px',
                        }}
                    >
                        <Button
                            style={{color: '#012441'}}
                            variant="text"
                            onClick={handleCloseModal}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                backgroundColor:
                                    !selectedDocumentType || !documentName
                                        ? '#b0c4de'
                                        : '#012441',
                                color: '#fff',
                            }}
                            variant="contained"
                            onClick={handleConfirm}
                            disabled={!selectedDocumentType || !documentName}
                        >
                            Continuar
                        </Button>
                    </div>
                </ModalContent>
            </Modal>
            {openAlertErro && (
                <Alert
                    style={{alignSelf: 'flex-end'}}
                    variant="filled"
                    severity="error"
                    onClose={() => {
                        setOpenAlertErro(false)
                    }}
                >
                    Seus Créditos para gerar documentos acabaram
                </Alert>
            )}
        </Page>
    )
}
